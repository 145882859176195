import React from 'react';

import { useQueryContext } from 'hooks';
import { successScenarios } from 'data/services.json';

import {
    Container,
    WavesBackgroundImage,
    Content,
    Title,
    ScenarioList,
    ScenarioCard,
    ScenarioCardTitle,
    ScenarioCardDescription,
    ScenarioCardRecommendation
} from './SuccessScenarios.style';

export function SuccessScenarios() {
    const {
        images: {
            services: {
                successScenarios: { waves }
            }
        }
    } = useQueryContext();

    return (
        <Container>
            <WavesBackgroundImage
                mobile={waves.mobile}
                tablet={waves.tablet}
                desktop={waves.desktop}
            >
                <Content>
                    <Title>{successScenarios.title}</Title>
                    <ScenarioList>
                        {successScenarios.scenarios.map((scenario) => {
                            const Icon = require(`../../../../static/images/services/${scenario.icon}`);
                            return (
                                <ScenarioCard key={scenario.title}>
                                    <Icon />
                                    <ScenarioCardTitle>{scenario.title}</ScenarioCardTitle>
                                    <ScenarioCardDescription>
                                        {scenario.description}
                                    </ScenarioCardDescription>
                                    <ScenarioCardRecommendation>
                                        <strong>{'Recommended for: '}</strong>
                                        {scenario.recommendation}
                                    </ScenarioCardRecommendation>
                                </ScenarioCard>
                            );
                        })}
                    </ScenarioList>
                </Content>
            </WavesBackgroundImage>
        </Container>
    );
}
