import styled from 'styled-components';

import { BackgroundImage } from 'components/ui';
import { Colors, MediaQueries } from 'environment';

export const Container = styled.section`
    padding-bottom: 12rem;
    overflow: visible;
`;

export const WavesBackgroundImage = styled(BackgroundImage)`
    .gatsby-image-wrapper {
        @media ${MediaQueries.desktop} {
            height: 59rem;
            top: 0;
        }

        @media ${MediaQueries.tablet} {
            height: 58rem;
            top: -22rem;
        }

        @media ${MediaQueries.phone} {
            height: 47rem;
            top: -11rem;
        }

        img {
            object-fit: fill !important;
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.h2`
    color: ${Colors.violet};
    text-align: center;
    font-weight: 500;

    @media ${MediaQueries.desktop} {
        margin-top: 36rem;
        margin-bottom: 12rem;
        max-width: 78rem;
    }

    @media ${MediaQueries.tablet} {
        margin-top: 12rem;
        margin-bottom: 8rem;
        max-width: 62rem;
    }

    @media ${MediaQueries.phone} {
        margin-top: 10rem;
        margin-bottom: 3rem;
        max-width: 34rem;
        font-size: 3.6rem;
        font-weight: bold;
    }
`;

export const ScenarioList = styled.div`
    display: flex;

    @media ${MediaQueries.phoneAndTablet} {
        flex-direction: column;
    }
`;

export const ScenarioCard = styled.div`
    padding: 4rem;

    background: ${Colors.white};
    border-radius: 2.5rem;
    box-shadow: 0 2rem 4rem -2rem rgba(77, 52, 91, 0.3);

    @media ${MediaQueries.desktop} {
        margin: 0 1rem;
        width: 58rem;
        height: 59rem;
    }

    @media ${MediaQueries.tablet} {
        margin-bottom: 4rem;
        width: 62rem;
    }

    @media ${MediaQueries.phone} {
        margin-bottom: 4rem;
        width: 33.5rem;

        svg {
            width: 12rem;
        }
    }
`;

export const ScenarioCardTitle = styled.h3`
    color: ${Colors.violet};
    margin-top: 4rem;
    margin-bottom: 4rem;
    font-weight: bold;

    @media ${MediaQueries.phone} {
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 1.58;
    }
`;

export const ScenarioCardDescription = styled.p`
    color: ${Colors.violet};
`;

export const ScenarioCardRecommendation = styled.p`
    margin-top: 2rem;
    color: ${Colors.violet};
`;
