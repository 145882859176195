import styled from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING, TABLET_GRID_SIZE } from 'consts';

export const Container = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;

    @media ${MediaQueries.desktop} {
        min-height: 43rem;
    }

    @media ${MediaQueries.tablet} {
        min-height: 46rem;
    }

    @media ${MediaQueries.phone} {
        min-height: 38rem;
    }
`;

export const Details = styled.div`
    color: ${Colors.violet};
    padding-top: 12rem;
    padding-bottom: 12rem;
    text-align: center;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 8 + DESKTOP_GRID_SPACING * 7}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.phone} {
        padding: 6rem 2rem;
    }
`;

export const Title = styled.h1`
    margin-bottom: 4rem;

    @media ${MediaQueries.phone} {
        font-size: 3.6rem;
        line-height: 5rem;
        font-weight: 700;
        margin-bottom: 2rem;
    }
`;

export const Description = styled.h4`
    font-weight: normal;
`;
