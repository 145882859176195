import React from 'react';

import {
    ServicesHero,
    ListOfServices,
    SuccessScenarios,
    OurProcess,
    Expertise
} from 'components/services';
import seo from 'data/seo.json';
import { SEO } from 'components/ui';

export default function Services() {
    return (
        <>
            <SEO
                title={seo.servicesTitle}
                description={seo.servicesDescription}
                ogTitle={seo.servicesOgTitle}
                ogDescription={seo.servicesOgDescription}
                ogImage={seo.servicesOgImage}
            />
            <ServicesHero />
            <ListOfServices />
            <SuccessScenarios />
            <OurProcess />
            <Expertise />
        </>
    );
}
