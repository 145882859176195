import styled from 'styled-components';

import { Colors, MediaQueries } from 'environment';
import { Icons } from 'environment';
import { FluidImage } from 'components/ui';
import { DESKTOP_GRID_SIZE, PHONE_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';

export const Container = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: visible; // allow bottom waves with negative bottom position

    @media ${MediaQueries.desktop} {
        padding-bottom: 45rem;
    }

    @media ${MediaQueries.tablet} {
        padding-bottom: 35rem;
    }

    @media ${MediaQueries.phone} {
        padding-bottom: 32rem;
    }
`;

export const StyledExpertiseCircles = styled(Icons.ExpertiseCircles)`
    position: absolute;
    right: 0;
    z-index: -1;

    @media ${MediaQueries.desktop} {
        top: -75rem;
        right: -47rem;
    }

    @media ${MediaQueries.tablet} {
        top: -13rem;
        right: -17.6rem;
        transform: scale(0.438);
        transform-origin: top right;
    }

    @media ${MediaQueries.phone} {
        top: -9rem;
        right: -17.7rem;
        transform: scale(0.338);
        transform-origin: top right;
    }
`;

export const Content = styled.div`
    flex-direction: column;
    overflow: visible; // allow for shadows

    @media ${MediaQueries.desktop} {
        width: ${DESKTOP_GRID_SIZE}rem;
    }

    @media ${MediaQueries.tablet} {
        width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.phone} {
        width: ${PHONE_GRID_SIZE}rem;
    }
`;

export const Title = styled.h2`
    color: ${Colors.violet};
    text-align: center;

    @media ${MediaQueries.desktop} {
        margin-top: 12rem;
        margin-bottom: 12rem;
    }

    @media ${MediaQueries.tablet} {
        margin-top: 12rem;
        margin-bottom: 8rem;
    }

    @media ${MediaQueries.phone} {
        font-size: 3.6rem;
        margin-top: 8rem;
        margin-bottom: 4.8rem;
        font-weight: bold;
    }
`;

export const ItemsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    @media ${MediaQueries.tablet} {
        flex-direction: column;
    }

    @media ${MediaQueries.phone} {
        flex-direction: column;
    }
`;

export const ExpertiseItemCard = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${Colors.white};
    border-radius: 2.5rem;
    box-shadow: 0 2rem 4rem -2rem rgba(77, 52, 91, 0.3);
    transition: background-color 200ms ease-in-out;
    color: ${Colors.violet};

    :hover {
        color: ${Colors.white};

        path {
            fill: ${Colors.white};
        }
    }

    :hover:nth-of-type(3n + 1) {
        background-color: ${Colors.orange};
    }

    :hover:nth-of-type(3n + 2) {
        background-color: ${Colors.magenta};
    }

    :hover:nth-of-type(3n + 3) {
        background-color: ${Colors.green};
    }

    @media ${MediaQueries.desktop} {
        width: 58rem;
        height: 34rem;
        margin-bottom: 4rem;
        padding: 4rem;
    }

    @media ${MediaQueries.tablet} {
        width: 100%;
        margin-bottom: 4rem;
        padding: 4rem;
    }

    @media ${MediaQueries.phone} {
        width: 100%;
        margin-bottom: 4rem;
        padding: 4rem;
    }
`;

export const ExpertiseItemTitle = styled.h4`
    font-weight: bold;
    text-transform: capitalize;

    @media ${MediaQueries.desktop} {
        margin-top: 4rem;
        margin-bottom: 2rem;
    }

    @media ${MediaQueries.tablet} {
        margin-top: 4rem;
        margin-bottom: 2rem;
    }

    @media ${MediaQueries.phone} {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
`;

export const ItemsList = styled.ul``;

export const ListItem = styled.li`
    font-size: 1.8rem;
    line-height: 2.8rem;
`;

export const WavesBottomImage = styled(FluidImage)`
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -1;

    .gatsby-image-wrapper {
        height: 58rem;

        img {
            object-fit: fill !important;
        }
    }
`;
