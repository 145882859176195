import React from 'react';

import content from 'data/services.json';
import { useQueryContext } from 'hooks';
import { BackgroundImage } from 'components/ui';

import { Container, Details, Title, Description } from './ServicesHero.style';

export function ServicesHero() {
    const {
        images: {
            services: { hero: image }
        }
    } = useQueryContext();

    return (
        <Container>
            <BackgroundImage
                mobile={image.mobile}
                tablet={image.tablet}
                desktop={image.desktop}
                alt={content.hero.alt}
            >
                <Details>
                    <Title>{content.title}</Title>
                    <Description>{content.description}</Description>
                </Details>
            </BackgroundImage>
        </Container>
    );
}
