import styled, { css } from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE, PHONE_GRID_SIZE } from 'consts';
import { BackgroundImage } from 'components/ui';

export const OurProcessBackground = styled(BackgroundImage)`
    .gatsby-image-wrapper {
        height: 100%;
    }
`;

export const Container = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.phone} {
        max-width: ${PHONE_GRID_SIZE}rem;
    }
`;

export const Title = styled.h2`
    color: ${Colors.white};

    @media ${MediaQueries.desktop} {
        margin-top: 12rem;
        margin-bottom: 8rem;
    }

    @media ${MediaQueries.tablet} {
        margin-top: 12rem;
        margin-bottom: 4rem;
    }

    @media ${MediaQueries.phone} {
        margin-top: 8rem;
        margin-bottom: 3.2rem;
        font-size: 3.6rem;
        font-weight: bold;
    }
`;

export const TogglContainer = styled.div`
    background-color: ${Colors.new.keplerTurquoiseSwitchButton};
    border-radius: 2.9rem;
`;

export const SwitchButton = styled.button<{ selected: boolean }>`
    margin: 0.2rem;
    color: ${Colors.white};
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.8rem;
    padding: 1rem 3rem;
    border: none;
    border-radius: 2.5rem;
    transition: background-color 300ms ease-in-out;
    -webkit-tap-highlight-color: transparent;

    ${({ selected }) =>
        selected
            ? css`
                  background-color: ${Colors.new.green};
                  cursor: default;
              `
            : css`
                  background-color: transparent;
                  cursor: pointer;
                  opacity: 0.6;
              `}
`;

export const ScenarioTitle = styled.h3`
    color: ${Colors.white};

    @media ${MediaQueries.desktop} {
        margin-top: 8rem;
        margin-bottom: 8rem;
    }

    @media ${MediaQueries.tablet} {
        margin-top: 8rem;
        margin-bottom: 8rem;
    }

    @media ${MediaQueries.phone} {
        margin-top: 4rem;
        margin-bottom: 4rem;
        font-size: 2.4rem;
        font-weight: 500;
    }
`;

export const StepsContainer = styled.div`
    display: flex;
    width: 100%;
    overflow: visible;
    justify-content: center;

    @media ${MediaQueries.desktop} {
        margin-bottom: 12rem;
    }

    @media ${MediaQueries.tablet} {
        flex-direction: column;
        margin-bottom: 8rem;
    }

    @media ${MediaQueries.phone} {
        flex-direction: column;
        margin-bottom: 4rem;
    }
`;

export const StepCard = styled.div`
    background-color: ${Colors.white};
    border-radius: 2.5rem;
    padding: 4rem;
    box-shadow: 0 2rem 4rem -2rem rgba(77, 52, 91, 0.3);

    @media ${MediaQueries.desktop} {
        width: 38rem;
        margin: 0 1rem;
        padding-bottom: 8rem;
        height: 100%;
        min-height: 74rem; // prevent section bounce on scenario switch
    }

    @media ${MediaQueries.tablet} {
        width: 100%;
        margin-bottom: 4rem;
    }

    @media ${MediaQueries.phone} {
        width: 100%;
        margin-bottom: 4rem;
    }
`;

export const StepTitle = styled.h4`
    margin-top: 4rem;
    margin-bottom: 2rem;
    color: ${Colors.violet};
    font-weight: bold;
`;

export const StepItems = styled.ul``;

export const StepItem = styled.li`
    color: ${Colors.violet};
    font-size: 1.8rem;
    line-height: 2.8rem;
`;
