import React from 'react';

import { expertise } from 'data/services.json';
import { useQueryContext } from 'hooks';

import {
    Container,
    Content,
    ExpertiseItemCard,
    ExpertiseItemTitle,
    ItemsContainer,
    ItemsList,
    ListItem,
    StyledExpertiseCircles,
    Title,
    WavesBottomImage
} from './Expertise.style';

export function Expertise() {
    const {
        images: {
            services: {
                expertise: { waves: wavesBackground }
            }
        }
    } = useQueryContext();

    return (
        <Container>
            <StyledExpertiseCircles />
            <Content>
                <Title>{expertise.title}</Title>
                <ItemsContainer>
                    {expertise.items.map((expertiseItem) => {
                        const ExpertiseItemIcon = require(`../../../../static/images/services/${expertiseItem.icon}`);
                        return (
                            <ExpertiseItemCard key={expertiseItem.title}>
                                <ExpertiseItemIcon />
                                <ExpertiseItemTitle>{expertiseItem.title}</ExpertiseItemTitle>
                                <ItemsList>
                                    {expertiseItem.items.map((item, index) => (
                                        <ListItem key={index}>{item}</ListItem>
                                    ))}
                                </ItemsList>
                            </ExpertiseItemCard>
                        );
                    })}
                </ItemsContainer>
            </Content>
            <WavesBottomImage {...wavesBackground} />
        </Container>
    );
}
