import React, { useState } from 'react';

import { ourProcess } from 'data/services.json';
import { useQueryContext } from 'hooks';

import {
    Container,
    OurProcessBackground,
    ScenarioTitle,
    StepCard,
    StepItem,
    StepItems,
    StepsContainer,
    StepTitle,
    SwitchButton,
    Title,
    TogglContainer
} from './OurProcess.style';

interface Scenario {
    identifier: string;
    title: string;
    availableSteps: number[];
}

export function OurProcess() {
    const {
        images: {
            services: {
                ourProcess: { background: backgroundImage }
            }
        }
    } = useQueryContext();

    const [selectedScenario, setSelectedScenario] = useState<Scenario>(ourProcess.scenarios[0]);

    function toggleScenarioHandler(selectedIndex: number) {
        setSelectedScenario(ourProcess.scenarios[selectedIndex]);
    }

    return (
        <section>
            <OurProcessBackground {...backgroundImage}>
                <Container>
                    <Title>{ourProcess.title}</Title>
                    <TogglContainer>
                        {ourProcess.scenarios.map((scenario, index) => (
                            <SwitchButton
                                key={scenario.identifier}
                                onClick={() => toggleScenarioHandler(index)}
                                selected={scenario.identifier === selectedScenario.identifier}
                            >
                                {scenario.identifier}
                            </SwitchButton>
                        ))}
                    </TogglContainer>
                    <ScenarioTitle>{selectedScenario.title}</ScenarioTitle>
                    <StepsContainer>
                        {selectedScenario.availableSteps.map((stepNumber) => {
                            const currentStep = ourProcess.steps[stepNumber];
                            const StepIcon = require(`../../../../static/images/services/${currentStep.icon}`);
                            return (
                                <StepCard key={stepNumber}>
                                    <StepIcon />
                                    <StepTitle>{currentStep.title}</StepTitle>
                                    <StepItems>
                                        {currentStep.descriptionItems.map((item) => (
                                            <StepItem key={item}>{item}</StepItem>
                                        ))}
                                    </StepItems>
                                </StepCard>
                            );
                        })}
                    </StepsContainer>
                </Container>
            </OurProcessBackground>
        </section>
    );
}
