import React from 'react';

import { listOfServices } from 'data/services.json';
import { Container, Feature, Label } from './ListOfServices.style';

export function ListOfServices() {
    return (
        <Container>
            {listOfServices.items.map((item, index) => {
                const Icon = require(`../../../../static/images/services/${item.icon.src}`);
                return (
                    <Feature key={`feature-container-${index}`}>
                        <Icon />
                        <Label>{item.title}</Label>
                    </Feature>
                );
            })}
        </Container>
    );
}
