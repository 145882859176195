import styled from 'styled-components';

import { MediaQueries, Colors } from 'environment';

export const Container = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;

    @media ${MediaQueries.tablet} {
        flex-direction: column;
        flex-wrap: wrap;
    }

    @media ${MediaQueries.phone} {
        flex-direction: column;
    }
`;

export const Feature = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media ${MediaQueries.desktop} {
        margin: 0 5.8rem;
    }

    @media ${MediaQueries.tablet} {
        margin-bottom: 4rem;
        width: 100%;
    }

    @media ${MediaQueries.phone} {
        margin-bottom: 8rem;
    }
`;

export const Label = styled.h4`
    color: ${Colors.violet};
    padding-top: 4rem;

    @media ${MediaQueries.phone} {
        padding-top: 2rem;
    }
`;
